import { SubButton } from "../shared/SubButton";

interface Props {
  openImportantNotes: () => void;
}

export const Reward: React.FC<Props> = ({ openImportantNotes }) => (
  <div className="w-full relative p-[26px] text-center">
    <div className="bg-gradient-to-l from-[#00E218] to-[#FFE500] absolute inset-0 w-full h-full -z-20" />
    <div className="bg-[url('/reward-bg.png')] bg-no-repeat bg-contain bg-top absolute top-0 left-0 w-full h-full -z-10" />

    <p className="text-black text-[24px] leading-[24px]">Rewards Overview</p>
    <p className="text-black text-[48px] leading-[48px] my-[10px]">
      <img src="/ton.svg" alt="icon01" className="w-12 h-12 inline-block mr-1" />
      2000<span className="text-[28px] ml-2">TON</span>
    </p>

    <p className="text-black text-[32px] leading-[32px] mt-[16px]">
      <img src="/ton.svg" alt="icon01" className="w-8 h-8 inline-block mr-1" />
      1000<span className="text-[28px] ml-2">TON</span>
    </p>
    <p className="text-black text-[14px] leading-[20px]">Airdrop by Raffle</p>

    <div className="w-full relative h-[65px] mt-[20px]">
      <p className="text-gradient text-[24px] leading-[55px] h-[55px] absolute inset-x-0 top-[10px] z-10 m-0">
        Registration campaign
      </p>
      <img src="/bg-registration-campaign.svg" alt="reward" className="w-full absolute top-0 left-0 z-0 h-[65px]" />
    </div>

    <div className="mx-[18px] border border-[#E5E5E5]">
      <div className="flex flex-raw justify-around items-center text-black text-[14px] leading-[28px] bg-white border-b border-[#E5E5E5]">
        <p>number of WINNERS</p>
        <p>Distribution amount</p>
      </div>

      <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%]">1</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          500
        </p>
      </div>
      <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%]">2</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          100×2
        </p>
      </div>
      <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%]">4</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          50×4
        </p>
      </div>
      <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%]">5</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          10×5
        </p>
      </div>
      <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%]">10</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          5×10
        </p>
      </div>
    </div>

    <p className="text-black text-[32px] leading-[32px] mt-[30px]">
      <img src="/ton.svg" alt="icon01" className="w-8 h-8 inline-block mr-1" />
      1000<span className="text-[28px] ml-2">TON</span>
    </p>
    <p className="text-black text-[16px] leading-[20px]">Airdrop by Raffle or GUaRANTEED</p>
    <p className="text-black/60 text-[12px] leading-[14px] text-left">
      ※For every 5, 10, or 20 people you invite, you will be eligible to participate in a prize draw based on the number
      of invites. Additionally, the top 1st and 2nd referrers are guaranteed to receive rewards.
    </p>

    <div className="w-full relative h-[65px] mt-[20px]">
      <p className="text-gradient text-[24px] leading-[55px] h-[55px] absolute inset-x-0 top-[10px] z-10 m-0">
        Referral campaign
      </p>
      <img src="/bg-registration-campaign.svg" alt="reward" className="w-full absolute top-0 left-0 z-0 h-[65px]" />
    </div>

    <div className="mx-[18px] border border-[#E5E5E5]">
      <div className="flex flex-raw justify-around items-center text-black text-[14px] leading-[28px] bg-white border-b border-[#E5E5E5]">
        <p>number of Invites</p>
        <p>Distribution amount</p>
      </div>

      <div className="flex flex-row justify-around items-center text-black text-[18px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%] text-gradient">the most inviter</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          400 (GUaRANTEED)
        </p>
      </div>
      <div className="flex flex-row justify-around items-center text-black text-[18px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%] text-gradient">the 2nd most inviter</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          200 (GUaRANTEED)
        </p>
      </div>
      <div className="flex flex-row justify-around items-center text-black text-[18px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%]">5</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          100×2 winners
        </p>
      </div>
      <div className="flex flex-row justify-around items-center text-black text-[18px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%]">10</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          10×10 winners
        </p>
      </div>
      <div className="flex flex-row justify-around items-center text-black text-[18px] leading-[36px] bg-white border-b border-[#E5E5E5]">
        <p className="text-center w-[45%]">20</p>
        <span className="angled-line w-[10%]" />
        <p className="text-center w-[45%]">
          <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
          1×200 winners
        </p>
      </div>
    </div>

    <p className="text-black text-[16px] leading-[20px] pt-4 pb-2">
      Special rewards may be revealed at each milestone of
      <br />
      10K, 50K, 100K, 150K, and 200K subscribers.
    </p>

    <img src="/present-box 1.png" alt="reward" className="w-15 h-15 mx-auto my-4" />

    <SubButton className="mx-auto h-[35px]" onClick={openImportantNotes}>
      ※Important notes
    </SubButton>
  </div>
);
