import { Footer as FooterSP } from "@/components/mobile/Footer";
import { Footer } from "@/components/pc/Footer";

import { Header as HeaderSP } from "@/components/mobile/Header";
import { Header } from "@/components/pc/Header";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";

export const PrivacyPolicy = () => {
  const isMobile = useMediaQuery("only screen and (max-width : 768px)");
  const navigate = useNavigate();

  return (
    <div>
      {isMobile ? <HeaderSP onClick={() => navigate("/")} /> : <Header onClick={() => navigate("/")} />}

      <div className="mt-24">
        <div className="text-[32px] md:text-[40px] text-white text-center">Privacy Policy</div>

        <div className="flex justify-center px-[20px]">
          <div className="bg-[#212121] text-[#989898] w-full max-w-[1000px] mx-auto p-5 my-10 din-font text-[14px] leading-[21px] py-[20px] px-[30px] border-[0.5px] border-[#515151]">
            <div className="text-right ">
              <p>Released: Nov 30, 2024</p>
              <p>Last Updated: Nov 30, 2024</p>
            </div>

            <div className="my-2">
              <h1 className="text-[16px] text-white pt-4 pb-1">Privacy Policy</h1>
              <p>
                Thumba reserves the right to revise or update this Privacy Policy at any time, to reflect changes in our
                practices, operations, or legal obligations. Any significant updates will be communicated to you,
                however it is your responsibility to stay informed of any changes and to periodically review this
                Privacy Policy. Continued use of the Service after any updates signifies your acknowledgement and
                agreement to the revised Privacy Policy.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">1. Data Collection and Use</h1>
              <p>
                By accessing and using Thumba, you agree to the collection and use of certain personal data, including
                but not limited to your Telegram ID, username, name, and any cryptocurrency wallet addresses you provide
                for transactions. This information is collected to manage your account, facilitate your participation in
                our games, and ensure the smooth operation of our services.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">2. Age Restrictions</h1>
              <p>
                Thumba is not intended for the use of anyone under the age of 18 (or 19, 21) depending on the Eligible
                Jurisdiction the user is located in. Thumba’s services are not targeted towards, or intended to be used
                by individuals below this minimum age. If you become aware or suspect that someone under the required
                age has provided us with personal information, please contact us immediately.
              </p>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">3. Compliance with Data Protection Laws</h1>
                <p>
                  We are dedicated to safeguarding your personal data in compliance with the Data Protection Act and
                  other applicable laws. To achieve this, we employ industry-standard security measures and adhere to
                  best business practices.
                </p>
                <p className="mt-2">
                  It is our priority to enforce and comply with the law. We may share your information with government
                  agencies, law enforcement, and relevant private firms, if we believe it is necessary to do so, in
                  order to carry out legal requests, protect our rights or the rights of others, to ensure public
                  safety, or to prevent and stop illegal or unethical activities. If legally allowed, we may try to
                  notify you when your information is shared as part of a legal process. Additionally, we may share
                  details like your registration, betting history, wallet data, or device information with regulatory
                  bodies to uphold betting rules, investigate suspected breaches, or prevent crime.
                </p>
              </div>

              <h1 className="text-[16px] text-white pt-4 pb-1">4. Data Usage</h1>
              <p>
                Your data is used to manage account activities, process transactions, and provide customer support.
                Authorized employees of the Company may access this information solely for fulfilling their duties and
                improving your experience. Your data may also be used to notify you about updates, promotions, and new
                services. You can opt out of these communications by muting or blocking the Telegram bot.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">5. Data Sharing</h1>
              <p className="inline-block">
                We do not share your personal data with third parties unless legally required or essential for service
                operations, such as wallet address transactions. Your data might be shared with service providers (for
                example, those responsible for the Telegram bot’s functionality), however they will only receive
                anonymized user IDs.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">6. Data Retention and Access</h1>
              <p>
                Your data is retained only as long as necessary for its intended purpose or as legally required. You
                have the right to access, update, or request deletion of your personal data by contacting our customer
                support team.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">7. Security Measures</h1>
              <p>
                We use robust security protocols, including encryption and secure transaction handling, to protect your
                data. While we take every reasonable step to secure your information, you are also responsible for
                safeguarding your account credentials.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">8. User Rights</h1>
              <p>
                You have the right to access, update, or delete your personal data. For any requests, please contact our
                customer support team, and we will your request in line with the applicable data protection laws.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">9. Cookies and Tracking Technologies</h1>
              <p className="inline-block">
                We utilize third-party services to analyze user interactions across our platforms. These services only
                have access to anonymized user IDs and do not handle or have access to personally identifiable
                information.We use this information to help us understand how we can improve Thumba’s services, and we
                never sell this information to third pirates.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">10. Transfer of Data Abroad</h1>
              <p>
                Thumba is registered within the US, however, if you utilise Thumba’s services from another country,
                there may be circumstances where your personal information might be transferred across international
                borders. Such transfers will occur only when necessary to fulfill our contract with you, with your
                consent, for our legitimate interests, or under appropriate legal safeguards.
              </p>
            </div>

            <div className="text-right pt-2">
              <p>DRAFTBEASTS INC. - All Rights Reserved © 2024</p>
              <p> Copyright © 2024</p>
            </div>
          </div>
        </div>
      </div>

      {isMobile ? <FooterSP /> : <Footer />}
    </div>
  );
};
