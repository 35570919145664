import { Footer as FooterSP } from "@/components/mobile/Footer";
import { Footer } from "@/components/pc/Footer";

import { useNavigate } from "react-router-dom";

import { Header as HeaderSP } from "@/components/mobile/Header";
import { Header } from "@/components/pc/Header";
import { useMediaQuery } from "@uidotdev/usehooks";

import { Icons } from "@/components/Icons";
import { MainButton } from "@/components/shared/MainButton";
import { cn } from "@/utils";
import { useRef, useState } from "react";

interface Faq {
  id: number;
  question: string;
  answer: string;
  image?: string;
  isSpecial?: boolean;
}

const faqData: Faq[] = [
  {
    id: 1,
    question: "What is Telegram?",
    answer:
      "With over 1 billion users, Telegram is not just a messaging service. It is one of the largest social media platforms, offering huge potential for brand exposure. Thumba's services can be accessed directly through the Telegram app.",
  },
  {
    id: 2,
    question: "What is TON?",
    answer:
      "TON (The Open Network) is a cryptocurrency that ranks among the top 20 globally recognized cryptocurrencies, according to CoinMarketCap. Introduced as the main coin of The Open Network blockchain, it has seen active integration with Telegram since 2022.",
  },
  {
    id: 3,
    question: "What is Fantasy Sports?",
    answer:
      "Fantasy sports are online games where players create virtual teams made up of real athletes from professional sports. These teams compete against each other based on the statistical performance of the athletes in real games. The concept combines elements of traditional sports management with a game-like structure, allowing fans to engage more deeply with the sports they love.",
  },
  {
    id: 4,
    question: "What is the service like?",
    answer:
      "This is a new fantasy sports service designed for the casual sports fan, taking just minutes to play. Traditionally, fantasy sports could take an average of 1-3 hours to participate in. With our service, you only need to choose between two player options five or eleven times to play.",
  },
  {
    id: 5,
    question: "What sports are supported?",
    answer:
      "Football will be supported at the time of the service launch, with American football, baseball, basketball, cricket, and tennis to be added later.",
  },
  {
    id: 6,
    question: "Can we play without owning cryptocurrency?",
    answer: "Yes, Thumba is free to play, so owning cryptocurrency is not required.",
  },
  {
    id: 7,
    question: "How to play?",
    answer:
      "1. Select players from A or B, and this process is repeated 5 or 11 times.\n2. The scores of the players you selected are reflected based on their performance in real-life matches, and your total score is calculated.\n3. Compete in real-time against other users who participate in the tournament.\n4. After the match ends, your ranking is determined based on your score, and prizes are distributed accordingly.",
  },
  {
    id: 8,
    question: "How do we rate the player's stats?",
    answer: "players stats will be defined due to the scoring points listed below.",
    image: "/faq-thumba-scoring.svg",
  },
  {
    id: 9,
    question: "Scoring and Rules",
    answer: "Scoring rules are listed below.",
    isSpecial: true,
  },
  {
    id: 10,
    question: "When do I need to finish entry?",
    answer:
      "Please complete your entry before the starters are announced. Unlike other fantasy sports services where you play after the starters are announced, Thumba allows you to play casually before the starters are revealed.",
  },
  {
    id: 11,
    question: "How long will it take to enter?",
    answer: "It only takes a minute!",
  },
  {
    id: 12,
    question: "If I win a prize, when will the money be sent?",
    answer:
      "The money is immediately transferred to your THUMBA wallet when the game ends. You are free to withdraw it at any time to the wallet of your choice.",
  },
  {
    id: 13,
    question: "Is Thumba safe?",
    answer: "Thumba is a safe platform as it can be played for free.",
  },
  {
    id: 14,
    question: "Which application can I use to enter?",
    answer: "You can use Telegram.",
  },
  {
    id: 15,
    question: "How do I deposit and withdraw?",
    answer: "Use Telegram wallet for Deposits and withdraws, and it can be done by using TON.",
  },
  {
    id: 16,
    question: "How to contact us?",
    answer:
      "You can either press the contact icon in the top right corner of the website or use the support bot link found in the profile of our Telegram channel.",
  },
];

export const FAQ: React.FC = () => {
  const [selected, setSelected] = useState<number | null>(null);
  const isMobile = useMediaQuery("only screen and (max-width : 768px)");
  const navigate = useNavigate();

  return (
    <div>
      {isMobile ? <HeaderSP onClick={() => navigate("/")} /> : <Header onClick={() => navigate("/")} />}

      <article className="mt-24">
        <div className="text-[32px] md:text-[40px] text-white text-center">FAQ</div>

        <div className="flex justify-center px-[5px] md:px-[20px]">
          <ul className="shadow-box max-w-[1000px]">
            {faqData.map((item) => (
              <FaqItem key={item.id} faq={item} selected={selected} setSelected={setSelected} />
            ))}
          </ul>
        </div>
      </article>

      <div className="flex justify-center w-full">
        <a href="https://t.me/ThumbaSupportBot">
          <MainButton className="w-[240px] mt-3">
            <img src="/telegram-black.svg" alt="Play" className="h-5" />
            Contact Us
          </MainButton>
        </a>
      </div>

      {isMobile ? <FooterSP /> : <Footer />}
    </div>
  );
};

interface AccordionItemProps {
  faq: Faq;
  selected: number | null;
  setSelected: (index: number | null) => void;
}

const FaqItem: React.FC<AccordionItemProps> = ({ faq, selected, setSelected }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isSelected = selected === faq.id;

  const toggle = () => {
    setSelected(isSelected ? null : faq.id);
  };

  return (
    <li className="relative my-1 bg-[#212121] text-[#989898]">
      <button type="button" className="w-full px-6 py-6 text-left" onClick={toggle}>
        <div className="flex items-center justify-between">
          <span className="text-[16px]leading-[20px] text-white">{faq.question}</span>

          <Icons.gradientChevronIcon isSelected={isSelected} gradientColors={["#00e218", "#ffe500"]} />
        </div>
      </button>

      <div
        className="relative overflow-hidden transition-all duration-300"
        style={{ maxHeight: isSelected ? containerRef.current?.scrollHeight : 0 }}
        ref={containerRef}
        onClick={toggle}
        onKeyDown={() => {}}
      >
        <div className={cn("px-6 pb-6", faq.image && "flex flex-col")}>
          <div className="text-[12px] leading-[20px]">
            {faq.answer.split("\n").map((line) => (
              <p key={line.substring(0, 3)}>{line}</p>
            ))}
          </div>

          {faq.image && (
            <img src={faq.image} alt="thumba scoring" className="w-[350px] md:w-[400px] lg:w-[600px] object-contain" />
          )}

          {faq.isSpecial && <ScoreAndRules />}
        </div>
      </div>
    </li>
  );
};

const ScoreAndRules: React.FC = () => {
  return (
    <ul className="pt-4 faq text-[12px] leading-[20px]">
      <li className="text-white text-[16px] leading-[20px]">
        Shots on target (Selected Player or Combination of Players)
      </li>
      <div className="ml-12">
        <p>Any intentional goal attempt which could result in:</p>
        <ul className="list-disc ml-4">
          <li>The ball goes into the net.</li>
          <li>The ball would have gone into the net but was stopped by a goalkeeper's save.</li>
          <li>The ball would have gone into the net but was stopped by a defender who is the last player.</li>
          <li>
            Shots hitting the frame of the goal are not counted as shots on target unless the above criteria are met.
          </li>
          <li>Shots blocked by another player, who is not the last player, are not counted as shots on target.</li>
        </ul>
      </div>

      <li className="text-white text-[16px] leading-[20px]">Shots (Selected Player or Combination of Players)</li>
      <div className="ml-12">
        <p>A shot is defined as any intentional goal attempt which could result in:</p>
        <ul className="list-disc ml-4">
          <li>The ball goes into the net.</li>
          <li>
            The ball would have gone into the net but was saved by the goalkeeper or stopped by an opposing player who
            is the last-player.
          </li>
          <li>
            The ball is heading towards goal and is blocked by a defender, where they were the last player meaning that
            there are not other defenders or a goalkeeper behind the blocker.
          </li>
          <li>
            The ball would have gone over or wide of the goal but was stopped by a goalkeeper's save or by an outfield
            player.
          </li>
          <li>The ball hits the frame of the goal.</li>
        </ul>
      </div>

      <li className="text-white text-[16px] leading-[20px]">Assists (Selected Player)</li>
      <div className="ml-12">
        <p>
          The final touch (pass, pass-cum-shot or any other touch) leading to the recipient of the ball scoring a goal
          without a decisive touch from an opposition player.
        </p>
        <p>Own goals or penalties do not get an assist awarded.</p>
      </div>

      <li className="text-white text-[16px] leading-[20px]">Passes (Selected Player)</li>
      <p className="ml-12">
        An intentional played ball from one player to another. Crosses, throw-ins, and keeper throws do not count as a
        pass. Goal kicks, free kicks, corners, kick-offs, and penalties can be played as a pass.
      </p>

      <li className="text-white text-[16px] leading-[20px]">Offsides (Match/Team)</li>
      <p className="ml-12">Awarded to the player deemed to be in an offside position where a free kick is awarded.</p>

      <li className="text-white text-[16px] leading-[20px]">Tackles (Team/Selected Player)</li>
      <div className="ml-12">
        <p>
          A tackle is defined as where a player connects with the ball in a ground challenge where they successfully
          take the ball away from the player in possession.
        </p>
        <p>The tackled player must clearly be in possession of the ball before the tackle is made.</p>
      </div>

      <li className="text-white text-[16px] leading-[20px]">Scoring Notes</li>
      <div className="ml-12">
        <ul className="list-disc">
          <li>A shot on goal will count as both a shot on goal as well as a shot. I.e. a total of +2 PTs.</li>
          <li>An accurate pass is defined as an intentionally played ball from one player to another.</li>
          <li>
            An assisted shot is defined as the final pass leading to the recipient of the ball having an attempt on
            goal.
          </li>
          <li>
            In the instance where a player gets 2 yellow cards, the player will NOT be given a red card. The player will
            get a total of -3 PTs.
          </li>

          <li>
            Goalkeepers/Defenders must play at least 60 minutes to qualify for the clean sheet bonus. The player's team
            (regardless if he is still playing or not) must allow zero goals over the course of regular time and extra
            time to qualify. Injury time is not counted in a player's total minutes played.
          </li>

          <li>
            Goalkeeper win stat is determined by the score after regulation time or extra time, if necessary. Penalty
            shootouts are not considered in determining a winning Goalkeeper.
          </li>
          <p>For two leg ties, the win stat will only consider the game being played, not the tie as a whole.</p>

          <li>
            Goalkeepers must play at least 60 minutes to qualify for a win. If a goalkeeper gets subbed off, his team
            must be winning at the time of substitution in order to be eligible for the win.
          </li>
          <p>The score at the time of substitution is not factored in for a Goalkeeper who is subbed into a match.</p>

          <li>
            A penalty kick save will count as +2 PTs for the save and an additional +3 PTs for the penalty kick save.
            Own goals do not count as goals for, but do count as goals against for Goalkeepers and do factor into Clean
            Sheet calculations.
          </li>
        </ul>
      </div>
    </ul>
  );
};
