import { cn } from "@/utils";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { RoadmapPC } from "./RoadmapPC";

const Avatar = ({
  title,
  name,
  alt,
  index,
  className,
  isMobile,
}: { title: string; name: string; alt: string; index: number; className?: string; isMobile: boolean }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const strokeWidth = isMobile ? "1px" : "2px";

  return (
    <div
      className={cn("flex flex-col justify-center items-center h-auto w-[106px] md:w-[236px]", className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ animationDelay: `${0.2 * index}s` }}
    >
      {isHovered ? (
        <img src={`/team-members/${name}-hover.png`} alt={alt} className="w-full select-none" />
      ) : (
        <img src={`/team-members/${name}.png`} alt={alt} className="w-full select-none" />
      )}

      <div
        className={`text-[12px] md:text-[30px] text-stroke shadow-md ${isHovered ? "bg-clip-text text-transparent bg-gradient-to-r from-[#FFFACD] to-[#AAFFB3]" : "text-shadow-lg"}`}
        style={{
          WebkitTextStroke: isHovered ? `${strokeWidth} black` : `${strokeWidth} white`,
          paintOrder: "stroke fill",
        }}
      >
        {title}
      </div>
    </div>
  );
};

const RoadMap = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div className="w-full mx-auto" ref={ref}>
      <p className={cn("text-white text-[32px] leading-[28px] text-center fadeInUp", inView && "fadeInUp-active")}>
        Road Map
      </p>
      <RoadmapPC className={cn("fadeInUp", inView && "fadeInUp-active")} />
    </div>
  );
};

export const OurTeam = ({ isMobile = false }: { isMobile?: boolean }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div className="w-full px-[30px] mx-auto" ref={ref}>
      <div
        className={cn(
          "text-white leading-[28px] text-[27px] md:text-[32px] text-center fadeInUp",
          inView && "fadeInUp-active",
        )}
      >
        <span className="text-[20px]">our</span>
        <br />
        Team Members
      </div>

      <div className="flex flex-col items-center w-full mt-[50px]">
        <div className="flex justify-center space-x-[15px] md:space-x-[17%] w-full">
          <Avatar
            title="Co-founder & CEO"
            name="J"
            alt="J"
            index={0}
            className={cn("fadeInUp", inView && "fadeInUp-active")}
            isMobile={isMobile}
          />
          <Avatar
            title="Co-founder & CTO"
            name="Liam"
            alt="Liam"
            index={1}
            className={cn("fadeInUp", inView && "fadeInUp-active")}
            isMobile={isMobile}
          />
          <Avatar
            title="Co-founder & CBO"
            name="Noah"
            alt="Noah"
            index={2}
            className={cn("fadeInUp", inView && "fadeInUp-active")}
            isMobile={isMobile}
          />
        </div>

        <div className="w-full relative h-[180px] mt-[30px] md:mt-0">
          <div className="flex w-full justify-center space-x-[15px] md:space-x-[17%] absolute top-[-25px]">
            <Avatar
              title="Co-founder & CMO"
              name="V"
              alt="V"
              index={3}
              className={cn("fadeInUp", inView && "fadeInUp-active")}
              isMobile={isMobile}
            />
            <Avatar
              title="Co-founder & CFO"
              name="Uno"
              alt="Uno"
              index={4}
              className={cn("fadeInUp", inView && "fadeInUp-active")}
              isMobile={isMobile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({
  image,
  link,
  index,
  className,
}: { image: string; link: string; index: number; className?: string }) => {
  return (
    <div
      className={cn(
        "bg-[#1E1E1E] w-[350px] md:w-[377px] rounded-[80px] h-[73px] md:h-[268px] hover:bg-gradient-to-r hover:from-[#FFFACD] hover:to-[#AAFFB3] p-[5px] md:p-[10px]",
        className,
      )}
      style={{ animationDelay: `${0.2 * index}s` }}
    >
      <a href={link} className="bg-[#1E1E1E] rounded-[70px] h-full w-full flex items-center cursor-pointer">
        <img src={image} alt="investors" className="mx-auto max-w-[150px] md:max-w-[200px]" />
      </a>
    </div>
  );
};

export const OurPartners = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div className="w-full lg:w-5/6 xl:w-3/4 2xl:w-2/3  mx-auto mt-10 md:mt-32" ref={ref}>
      <p
        className={cn(
          "text-white text-[27px] md:text-[32px]  leading-[28px] text-center mb-8 fadeInUp",
          inView && "fadeInUp-active",
        )}
      >
        <span className="text-[20px]">our</span>
        <br />
        Partners
      </p>

      <div className="w-full flex flex-col md:flex-row justify-center md:justify-between items-center md:items-center space-y-[7px] md:space-x-5">
        <Card
          image="/partners/ton-japan.png"
          link="https://ton-japan.org/"
          index={0}
          className={cn("fadeInUp", inView && "fadeInUp-active")}
        />
        <Card
          image="/partners/degame.png"
          link="https://degame.com/en/home"
          index={1}
          className={cn("fadeInUp", inView && "fadeInUp-active")}
        />
        <Card
          image="/partners/sportradar.png"
          link="https://sportradar.com/"
          index={2}
          className={cn("fadeInUp", inView && "fadeInUp-active")}
        />
      </div>
    </div>
  );
};

export const OurInformation = () => {
  return (
    <div className="w-full bg-[url('/our-information-bg.png')] bg-cover bg-top bg-no-repeat relative py-[100px]">
      <div className="absolute inset-0 bg-gradient-to-b from-black from-0% via-transparent via-5% from-98% -z-10" />
      <div className="absolute inset-0 bg-gradient-to-t from-black from-0% via-transparent via-10% from-98% -z-10" />

      <RoadMap />

      <OurTeam />

      <OurPartners />
    </div>
  );
};
