import { cn } from "@/utils";
import { useInView } from "react-intersection-observer";

type Sport = {
  id: number;
  name: string;
  image: string;
  isComingSoon: boolean;
  comingSoonColor?: string;
};

export const sports: Sport[] = [
  {
    id: 1,
    name: "Soccer",
    image: "/contents/content01.png",
    isComingSoon: false,
  },
  {
    id: 2,
    name: "Basketball",
    image: "/contents/content02.png",
    isComingSoon: true,
    comingSoonColor: "#67E30E",
  },
  {
    id: 3,
    name: "Baseball",
    image: "/contents/content03.png",
    isComingSoon: true,
    comingSoonColor: "#9BE409",
  },
  {
    id: 4,
    name: "Unique",
    image: "/contents/content04.png",
    isComingSoon: true,
    comingSoonColor: "#67E30E",
  },
];

export const Sport = ({ sport, className }: { sport: Sport; className?: string }) => {
  return (
    <div
      className={cn(
        "relative w-[calc(25%-1rem)] p-2 bg-transparent hover:bg-gradient-to-r hover:from-[#FFFACD] hover:to-[#AAFFB3]",
        className,
      )}
      style={{ animationDelay: `${0.2 * sport.id}s` }}
    >
      <div className="w-full">
        <img src={sport.image} alt={sport.name} className="w-full object-cover z-0" />

        {sport.isComingSoon && (
          <div className="absolute inset-0 bg-black/80 flex items-center justify-center z-10">
            <span
              style={{ color: sport.comingSoonColor }}
              className="text-center text-white text-[75px] leading-[75px]"
            >
              Coming <br /> Soon!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const Sports = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 500,
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <div className="w-full h-full text-center mt-[80px] max-w-[1440px] mx-auto">
        <p className={cn("text-white text-[32px] leading-[40px] fadeInUp", inView && "fadeInUp-active")}>Sports</p>
      </div>

      <div className="w-full max-w-[1440px] mx-auto flex flex-wrap justify-between gap-4 my-[40px]">
        {sports.map((sport) => (
          <Sport key={sport.id} sport={sport} className={cn("fadeInUp", inView && "fadeInUp-active")} />
        ))}
      </div>

      <div className="w-full flex justify-center">
        <div className="scroll-container">
          <div className="scroll-content">
            {Array.from({ length: 48 }, (_, i) => {
              const num = (i % 16) + 1;
              const formattedNum = num.toString().padStart(3, "0");
              return (
                <img
                  key={`sport-pc-${i}-${formattedNum}`}
                  src={`/sports/${formattedNum}.svg`}
                  alt={formattedNum}
                  className="w-6 h-6 p-4 box-content mx-1 bg-[#0B0B0B] border border-[#313131]"
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
