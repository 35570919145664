import { Footer as FooterSP } from "@/components/mobile/Footer";
import { Footer } from "@/components/pc/Footer";

import { Header as HeaderSP } from "@/components/mobile/Header";
import { Header } from "@/components/pc/Header";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";

export const AmlPolicy = () => {
  const isMobile = useMediaQuery("only screen and (max-width : 768px)");
  const navigate = useNavigate();

  return (
    <div>
      {isMobile ? <HeaderSP onClick={() => navigate("/")} /> : <Header onClick={() => navigate("/")} />}

      <div className="mt-24">
        <div className="text-[32px] md:text-[40px] text-white text-center">AML Policy</div>

        <div className="flex justify-center px-[20px]">
          <div className="bg-[#212121] text-[#989898] w-full max-w-[1000px] mx-auto p-5 my-10 din-font text-[14px] leading-[21px] py-[20px] px-[30px] border-[0.5px] border-[#515151]">
            <div className="text-right ">
              <p>Released: Nov 30, 2024</p>
              <p>Last Updated: Nov 30, 2024</p>
            </div>

            <div className="py-2">
              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Policy approval</h1>
                <p>
                  Thumba is owned and operated by DraftBeasts Inc (1007 N Orange St. 4th Floor Suite #3794, Wilmington,
                  Delaware 19801, United States).
                </p>
                <p className="mt-2">
                  The company is further approved to accept and transact with customers in cryptocurrencies. We are
                  dedicated to ensuring our platform is not being used for Money Laundering, Terrorist Financing, or
                  other illicit activities. To achieve this, we implement the following measures:
                </p>
              </div>

              <h1 className="text-[16px] text-white pt-4 pb-1">Commitment to Compliance</h1>
              <p>
                Thumba is committed to applying robust measures to prevent Money Laundering and Combating Terrorism
                Financing (AML/CTF). We recognise our responsibility to prevent Thumba from being misused for criminal
                purposes. Our policies and procedures are regularly reviewed and updated to align with the latest legal,
                regulatory, and industry standards.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">Laws and regulations</h1>
              <p>
                Thumba upholds its responsibility to comply with Delaware state laws, U.S. federal regulations, and
                international AML/CTF standards to prevent Money Laundering and Terrorist Financing.
                <br />
                We actively implement effective AML/CTF measures to prevent our systems from being misused for illicit
                activities.
                <br />
                Our AML/CTF policies are regularly reviewed and updated to ensure compliance with evolving legal and
                regulatory requirements in Delaware and the U.S.
              </p>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Customer Due Diligence</h1>
                <p>
                  We conduct comprehensive customer due diligence (CDD) during account registration. This includes
                  verifying name, age, residential address, nationality, conducting checks for Politically Exposed
                  Persons (PEPs) and a sanctions check.
                </p>
                <p className="mt-2">
                  Enhanced due diligence (EDD) is applied when a customer’s risk profile raises concerns and warrants
                  closer scrutiny. This may involve:
                  <ul className="list-disc ml-5">
                    <li>Verification of identity using official documents.</li>
                    <li>Analysis of publicly available data and third-party sources.</li>
                    <li>Cross-referencing corporate or financial information.</li>
                  </ul>
                </p>
                We continuously monitor customer relationships to be in line with industry best practices and
                international AML/CTF standards.
                <br />
                Suspicious activities or transactions will be promptly reported to the relevant law enforcement
                authorities as required.
                <br />
                Where due diligence cannot be completed, business relationships will be suspended until compliance
                requirements are fully met.
              </div>
              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Cryptocurrency Transactions</h1>

                <p>
                  The company reserves the right to perform additional background and security checks for the customers
                  depositing or withdrawing cryptocurrencies (?). In cases of suspicion, we may suspend the account and
                  request further documentation to complete enhanced due diligence.
                </p>

                <p className="mt-2">
                  If we cannot verify the customer’s identity or the source of their funds, the matter will be escalated
                  to senior management and relevant service providers for review. If necessary, customer funds may be
                  held in a seized funds account and reported as part of a suspicious activity report (SAR).
                </p>
              </div>

              <h1 className="text-[16px] text-white pt-4 pb-1">Reporting suspicions</h1>
              <p>
                Suspicious activity involving Money Laundering, Terrorist Financing, or other criminal behavior will be
                reported through an SAR.
                <br />
                Customers identified on sanctions lists or linked to unlawful activities will be subject to immediate
                review, and their accounts may be frozen, with funds retained in compliance with Delaware and federal
                laws.
              </p>
            </div>

            <div className="text-right mt-2">
              <p>DRAFTBEASTS INC. - All Rights Reserved © 2024</p>
              <p> Copyright © 2024</p>
            </div>
          </div>
        </div>
      </div>

      {isMobile ? <FooterSP /> : <Footer />}
    </div>
  );
};
