import type { CampaignStep } from "@/hooks/useUserCampaign";
import { CampaignButton } from "./CampaignButton";

interface Props {
  campaign: CampaignStep;
  telegramUserId: number;
}

export const Campaign: React.FC<Props> = ({ campaign, telegramUserId }) => {
  return (
    <>
      <div className="w-full text-center" id="campaign-mobile">
        <div className="bg-gradient-to-l from-[#00E218] to-[#FFE500]">
          <div className="py-2 px-4">
            <p className="text-black text-[18px] leading-[26px] mt-[14px]">Pre-Registration Campaign Overview</p>
            <p className="text-black text-[54px] leading-[64px]">
              <img src="/ton.svg" alt="icon01" className="w-10 h-10 inline-block mr-1" />
              2000<span className="text-[28px] ml-2">TON</span>
            </p>
            <p className="text-black text-[18px] leading-[16px] mb-[14px]">
              AIRDROP!!​
              <br />
              Join this campaign and earn free Ton!!​
            </p>
          </div>

          <div className="w-full p-[1px]">
            <div className="w-[calc(100%-2px)] bg-[#000000]/80 border border-transparent py-[10px]">
              <span className="text-gradient text-[18px] leading-[26px]">
                Pre-Registration 9/10 ~ Could be tomorrow
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-left">
        <div className="p-[22px]">
          <p className="text-center text-[#989898]">How to Participate</p>

          <p className="text-white text-[20px] leading-[35px] pt-4">Main Campaign</p>

          <CampaignButton
            currentStep={campaign.currentStep}
            step={1}
            telegramUserId={telegramUserId}
            title="Join the Campaign"
            activeChildren={
              <>
                <img src="/telegram-black.svg" alt="Play" className="h-5" />
                THUMBA bot​
              </>
            }
            inactiveChildren={
              <>
                <img src="/telegram-white.svg" alt="Play" className="h-3" />
                THUMBA bot​
              </>
            }
          />

          <CampaignButton
            currentStep={campaign.currentStep}
            step={2}
            telegramUserId={telegramUserId}
            title="Join the Telegram community"
            activeChildren={
              <>
                <img src="/telegram-black.svg" alt="Play" className="h-5" />
                Thumba official
              </>
            }
            inactiveChildren={
              <>
                <img src="/telegram-white.svg" alt="Play" className="h-3" />
                Thumba official
              </>
            }
          />

          <CampaignButton
            currentStep={campaign.currentStep}
            step={3}
            telegramUserId={telegramUserId}
            title="Follow Thumba X Account"
            activeChildren={
              <>
                <img src="/x-black.svg" alt="Play" className="h-5" />
                Thumba X
              </>
            }
            inactiveChildren={
              <>
                <img src="/x-white.svg" alt="Play" className="h-3" />
                Thumba X
              </>
            }
          />

          <CampaignButton
            currentStep={campaign.currentStep}
            step={4}
            telegramUserId={telegramUserId}
            title="Like and RT our X tweet"
            activeChildren={
              <>
                <img src="/x-black.svg" alt="Play" className="h-5" />
                Thumba X POST
              </>
            }
            inactiveChildren={
              <>
                <img src="/x-white.svg" alt="Play" className="h-3" />
                Thumba X POST
              </>
            }
          />
        </div>
      </div>
    </>
  );
};
