import { Footer as FooterSP } from "@/components/mobile/Footer";
import { Footer } from "@/components/pc/Footer";

import { Header as HeaderSP } from "@/components/mobile/Header";
import { Header } from "@/components/pc/Header";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";

export const TermsAndConditions = () => {
  const isMobile = useMediaQuery("only screen and (max-width : 768px)");
  const navigate = useNavigate();

  return (
    <div>
      {isMobile ? <HeaderSP onClick={() => navigate("/")} /> : <Header onClick={() => navigate("/")} />}

      <div className="mt-24">
        <div className="text-[32px] md:text-[40px] text-white text-center">Terms And Conditions</div>

        <div className="flex justify-center px-[20px]">
          <div className="bg-[#212121] text-[#989898] w-full max-w-[1000px] mx-auto p-5 my-10 din-font text-[14px] leading-[21px] py-[20px] px-[30px] border-[0.5px] border-[#515151]">
            <div className="text-right ">
              <p>Released: Nov 30, 2024</p>
              <p>Last Updated: Nov 30, 2024</p>
            </div>

            <div className="my-2">
              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Introduction</h1>
                <p>
                  Welcome to Thumba! These Terms and Conditions govern your access to and use of our platform, including
                  our fantasy sports game. By using Thumba's services, you acknowledge that you accept and agree to
                  these Terms and Conditions. Please make sure to review them carefully before using Thumba.
                </p>
              </div>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Definitions</h1>
                <ul className="list-disc ml-5 pt-4">
                  <li>Thumba: Referred to as "we," "us," "our," "Company," "platform," or "service."</li>
                  <li>User: Refers to "you," "Account Holder," "Player," "Member," "Customer," or "User(s)."</li>
                  <li>
                    Cryptocurrencies: Refers to digital assets, including but not limited to TON (The Open Network).
                  </li>
                </ul>

                <p className="mt-2">
                  Thumba is a fantasy sports game where Users select players, build unique teams, and compete to predict
                  the total player scores. The goal is to assemble the highest-scoring team and win fixed prizes based
                  on your ranking.
                </p>

                <p className="mt-2">
                  In order to place bets with real money (including Cryptocurrencies), you are considered to have
                  understood, accepted, and agreed to be irrevocably bound by the stated Terms and Conditions. {" "}
                </p>
              </div>

              <h1 className="text-[16px] text-white pt-4 pb-1">Acceptance of the Terms and Conditions</h1>
              <p>
                By using Thumba's services, you are acknowledging that you have read and understood, and agree to be
                bound by the Terms and Conditions, Privacy Policy, and AML Policy. It also represents that you are of
                legal age to enter into a binding agreement, and that you accept the Terms and Conditions and agree that
                you are irrevocable, legally bound by them. If you do not agree to these requirements, you must
                immediately stop using Thumba's services and deactivate your account.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">Modifications to the Terms and Conditions</h1>
              <p>
                We reserve the right, at our sole discretion, to modify or replace the Terms and Conditions at any time
                and without advanced notice. Any changes to the Terms and Conditions will be effective immediately upon
                posting on the Service. It is your responsibility as the user to check the Terms and Conditions
                regularly for any updates or changes. By continuing to use the Service after any changes to the Terms
                and Conditions constitutes your acceptance and agreement to be bound by the updated Terms and
                Conditions.
              </p>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Binding Declarations</h1>
                <ol className="list-decimal ml-5 pt-4">
                  <li>
                    You are at least (a) 18 years old and (b) of the the legal age or age of majority required by the
                    laws applicable to the jurisdiction you are located in, whichever is higher;
                  </li>
                  <li>
                    You possess full legal capacity to enter into a binding agreement with the Company and are not
                    subject to any restrictions on your legal ability to do so;
                  </li>
                  <li>
                    Your participation in contests is solely for personal, recreational, and entertainment purposes, and
                    not for professional or commercial use;
                  </li>
                  <li>
                    You participate in Contests and place bets exclusively on your own behalf and never on the behalf of
                    any third party;
                  </li>
                  <li>
                    You are solely responsible for reporting and paying any taxes, fees, or charges associated with any
                    winnings, as required by the laws of the your jurisdiction;
                  </li>
                  <li>
                    You acknowledge and accept the risk of losing money you deposit into the Game, and you are fully and
                    solely responsible for any such losses;
                  </li>
                  <li>
                    You will not use our services if you are located in any jurisdiction listed as a Restricted
                    Jurisdiction (NOT CREATED YET);
                  </li>
                  <li>
                    You are legally permitted to use fantasy sports services in the jurisdiction where you are located;
                  </li>
                  <li>
                    When making deposits or withdrawals into and from the Service, you will only use cryptocurrency that
                    is valid and legally owned by you;
                  </li>
                  <li>
                    You acknowledge and accept that the value of cryptocurrency may fluctuate significantly depending on
                    market conditions;
                  </li>
                  <li>
                    All software, graphics, websites, and user interfaces provided by the Company are owned by the
                    Company or its affiliates and are protected by copyright laws;
                  </li>
                  <li>
                    You acknowledge that cryptocurrency is not considered legal currency or tender and is treated as
                    virtual funds with no intrinsic value on the platform;
                  </li>
                  <li>
                    You confirm that you are not an officer, director, employee, consultant, agent, or relative of any
                    such individual associated with the Company or its affiliates;
                  </li>
                  <li>
                    You are not classified as a compulsive or problem gambler. While we are not responsible for any
                    gambling issues that may arise while using our services, we will make relevant support information
                    available. We may impose cooling-off periods at your request or if we deem it necessary;
                  </li>
                  <li>
                    If you are a politically exposed person (PEP) or a close family member of one, you accept that your
                    account may be reviewed or suspended without prior notice to comply with our due diligence
                    requirements. Decisions regarding the status of your Account, including permanent closure, are at
                    the sole discretion of the Company.
                  </li>
                  <li>
                    You acknowledge and accept that we reserve the right to detect and prevent the use of prohibited
                    activities or techniques, including but not limited to fraudulent transactions, gameplay
                    manipulation, and screen capture methods. This may involve reviewing device properties, detecting
                    geo-location and IP masking, transaction analysis, and blockchain audits.
                  </li>
                  <li>
                    You accept that Thumba has the right to terminate or modify any Contests or Events on the Service
                    and may refuse or limit bets at its sole discretion.
                  </li>
                </ol>
              </div>

              <h1 className="text-[16px] text-white pt-4 pb-1">Reservation of Rights</h1>
              <p>
                We use the Player Portrait Rights under a license agreement with Sportradar (https://sportradar.com/).
                The User and any third parties are prohibited from filing legal applications or lawsuits regarding the
                player portrait rights.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">Thumba Account Eligibility</h1>
              <p>
                The use of the service is restricted to individuals 18 years of age or older. As the User of this
                Service, you solely bear full responsibility for providing truthful and accurate information concerning
                your age, date of birth, and location. If you become aware of any unauthorized use of your account, you
                must immediately notify us to take the necessary actions.
              </p>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Account Misuse</h1>
                <p>
                  You acknowledge and agree that the sole purpose of creating an Account on Thumba is to participate in
                  Contests. Thumba reserves the right to suspend, limit, deactivate or terminate your Account, if we
                  determine, at our sole discretion, that you are violating these Terms and Conditions.
                </p>
                <p className="mt-2">
                  Each User may only establish one Account. Users are prohibited from "co-owning" or sharing Accounts.
                  Users must not control, share, or access another person's Account in any manner, including using it as
                  a proxy to make contest entries or to circumvent any restrictions, limitations, or suspensions placed
                  on their own Account.
                </p>
              </div>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Account Confidentiality</h1>
                <p>
                  You are solely responsible for maintaining the confidentiality of your Account. You agree to accept
                  full responsibility for all activities, charges, and damages that occur under your Account.
                  <br />
                  If you become aware of any unauthorized use of your Account or any other security breach related to
                  your Account, you must report it to Thumba immediately.
                </p>
              </div>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Eligibility for Contests</h1>
                <p>
                  To enter Contests you must be physically located within a jurisdiction where Thumba is legally
                  available for consumer play. You must be at least 18, 19 or 21 years of age dependent on the eligible
                  jurisdiction in which you are present, to be eligible to participate in Contests and win prizes
                  offered through Thumba. It is your responsibility to consult each eligible jurisdiction's rules to
                  ensure you meet the age and other eligibility requirements.
                </p>
                <p className="mt-2">
                  At the time of Deposit or Contest lineup submission, you must be physically located in an Eligible
                  Jurisdiction. If you are listed on any governmental list of prohibited, restricted or self-excluded
                  individuals to fantasy sports, you are not eligible to use Thumba's services.
                </p>
              </div>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Deposits</h1>
                <p>
                  You may participate in any contest only if you have sufficient funds on the platform for such
                  participation.
                </p>
                <ol className="list-decimal ml-5 pt-4">
                  <li>
                    Minimum and maximum limits may be applied to Deposits, depending on your history with the Service.
                  </li>
                  <li>You may make deposits in Cryptocurrencies. The minimum deposit amount is 0.1 TON.   </li>
                  <li>
                    To deposit funds into the platform, you can transfer funds from your crypto wallets. All payments
                    into your Account must be from a payment source on which you are named the Account holder.
                  </li>
                  <li>
                    Some payment methods may include additional fees. You are responsible to bear the weight of such
                    fees.
                  </li>
                  <li>We are not a bank and funds are not insured by any governmental agency. </li>
                  <li>
                    If necessary, you may be required to provide appropriate documentation that allows us to verify your
                    identity. If we are unable to verify your identity, Thumba reserves the right to suspend your
                    Account and withhold any funds until such time as we have been able to successfully verify you.
                  </li>
                </ol>
              </div>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Withdrawals</h1>
                <p>
                  You may request a withdrawal of funds from the available balance in your Thumba account at any time.
                  Withdrawals will be made to your designated Cryptocurrency wallet address, which can be updated before
                  each withdrawal. You are the only individual or entity authorized to withdraw funds from your Account.
                  The minimum withdrawal is 0.2 TON per single transaction. If you have pending deposits, you must wait
                  until those funds are cleared before requesting a withdrawal.
                </p>

                <p className="mt-2">
                  We do not support withdrawals to another Thumba account or deposits from one Thumba account to another
                  Thumba account.
                  <br />
                  In the event that funds are mistakenly credited to your wallet as winnings, whether due to a technical
                  error, human error, or any other reason, those funds remain the property of Thumba. You are required
                  to notify Thumba immediately upon discovering such an error. If mistaken winnings are withdrawn to
                  your wallet, you are obligated to repay the amount. Failure to repay may result in legal actions to
                  recover the funds, and this obligation is enforceable by law.
                  <br />
                  Thumba reserves the right to conduct additional Know Your Customer (KYC) verification procedures for
                  withdrawals exceeding $X (or equivalent in any cryptocurrency). Thumba may also conduct verification
                  for smaller withdrawals at its discretion. For larger withdrawal requests, Thumba may process up to 1
                  Million USD (or equivalent in other currencies) per week until the full amount is withdrawn.
                  <br />
                  You acknowledge that your funds are consumed instantly when playing, and we do not provide a return of
                  goods, refunds, or cancellations after the transaction is made. We reserve the right to deduct any
                  incurred transaction fees or other costs from your withdrawal amount.
                </p>

                <p className="mt-2">
                  Certain promotions may be subject to specific withdrawal restrictions or requirements, which must be
                  met before you can withdraw the funds earned through those promotions. These terms will be clearly
                  outlined as part of the promotion. If you attempt to withdraw funds before meeting the required
                  criteria, the reward amount, along with any winnings derived from the promotion, will be deducted.
                  Thumba reserves the right to set geographical limitations on certain reward schemes at its discretion.
                </p>

                <p className="mt-2">
                  In the event that your account is closed due to a violation of the Terms and Conditions, Thumba has
                  the right to determine, at its sole discretion, whether to cancel any transactions you have made. If a
                  prize was awarded to a closed account due to fraudulent activity, Thumba may withhold the prize and
                  reassign it to the rightful winner.
                  <br />
                  Additionally, if you have already withdrawn funds (whether prizes or other funds not won through
                  contests) that, in Thumba’s sole judgment, resulted from violations of these Terms or any applicable
                  laws or regulations, Thumba reserves the right to recover those funds. Any administrative costs
                  associated with enforcing these Terms may be deducted from your account balance.
                </p>
              </div>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Prizes</h1>
                <p>
                  You acknowledge and agree that if we, in our sole and absolute discretion, determine that you failed
                  to comply with and or adhere to the Terms and Conditions, in any way, we may disqualify you from any
                  Contest entered. In such cases, any prizes awarded to you as a result of said Contest will be
                  immediately forfeited. You agree to cooperate fully with our efforts to reverse any such payments.
                </p>

                <p className="mt-2">
                  All winning will be deposited directly into your Account. Any amounts that are mistakenly credited as
                  winnings to your account remain the property of the Company and will automatically be transferred from
                  your Account upon confirmation of the error. If amounts mistakenly credited to your Account are
                  withdrawn by you before confirmation of the error, such amounts will constitute a debt owed by you to
                  the Company.
                </p>
              </div>

              <h1 className="text-[16px] text-white pt-4 pb-1">Contest Disqualification and Cancellation</h1>
              <p>
                All paid entry fees are final. No refunds will be issued under any circumstances. In the event of a
                dispute regarding the identity of the individual submitting a lineup, the lineup will be deemed
                submitted by the person in whose name the Account was registered. Thumba reserves the right to
                disqualify any participant or cancel any contest if necessary, in accordance with the Terms and
                Conditions.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">Warranty Disclaimer</h1>
              <p>
                When you use Thumba’s site or its services, you acknowledge and accept that you do so at your own risk.
                Thumba provides no guarantees of the site and the services being perfect or free of issues. Thumba
                cannot guarantee that the content on our site and services is accurate, complete, or free of errors, and
                that Thumba’s services will function flawlessly. This extends to any linked sites on Thumba’s site and
                services, and their content. Thumba is not responsible for any issues or inaccuracies. Any information
                that you receive from Thumba’s team, whether that be verbally or in writing, is not legal or financial
                advice and thus does not come with any guarantees.
              </p>

              <div>
                <h1 className="text-[16px] text-white pt-4 pb-1">Limitation of Liability</h1>
                <p>
                  Thumba and its team (including any third-party providers) are not responsible for any harm or damages
                  the user may experience, such as:
                </p>

                <ol className="list-decimal ml-5 pt-4">
                  <li>Mistakes or missing information on the service or linked site.</li>
                  <li>Personal injuries, property damage, or harm caused by third parties.</li>
                  <li>Unauthorized access to your data.</li>
                  <li>Service interruptions, viruses, or harmful content.</li>
                  <li>Offensive or inappropriate content from other users</li>
                </ol>
              </div>

              <h1 className="text-[16px] text-white pt-4 pb-1">Force Majeure</h1>
              <p>
                Thumba shall not be considered in breach of its service obligations to you, the User, for any failure or
                delay in performance that Thumba deems to be caused by a force majeure event. Force majeure events
                include, but are not limited to, hurricanes, floods, fires, tornadoes, wars, riots, earthquakes, acts of
                terrorism, pandemics, health emergencies (regardless of official government declaration), failures of
                public utility electrical systems, lockouts, strikes, or delays or disruptions in internet and
                telecommunications networks due to human or natural causes, or any event beyond the reasonable control
                of Thumba. Thumba shall bear no liability for any consequences resulting from such force majeure events.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">Applicable Law and Jurisdiction</h1>
              <p>
                If you violate any provision of these Terms and Conditions, or we have a reasonable ground to suspect
                that you have breached them, we reserve the right to immediately suspend your use to the Service and
                block your access to the platform.
                <br />
                Upon termination of your account, your license to use Thumba’s content automatically ends and Thumba
                will have no obligation to provide you with access to the services.
                <br />
                You may terminate your account by ceasing to use the Service at any time.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">Dispute Resolution</h1>
              <p>
                These terms and conditions are governed by the laws of the State of Delaware. Any dispute relating to
                this Agreement or the Service shall be subject to the exclusive jurisdiction of the courts located in
                the State of Delaware. The involved parties agree to waive their right to a jury trial in any legal
                action or proceeding related to these Terms and Conditions or the Services.
              </p>

              <h1 className="text-[16px] text-white pt-4 pb-1">Customer Support</h1>
              <p className="inline-block">
                If you have any queries or complaints regarding the Service, please contact Customer Support at{" "}
                <a href="https://t.me/ThumbaSupportBot">https://t.me/ThumbaSupportBot</a>. We will endeavor to respond
                promptly.
              </p>
            </div>

            <div className="text-right mt-2">
              <p> DRAFTBEASTS INC. - All Rights Reserved © 2024</p>
              <p> Copyright © 2024</p>
            </div>
          </div>
        </div>
      </div>

      {isMobile ? <FooterSP /> : <Footer />}
    </div>
  );
};
