import { useEffect, useRef, useState } from "react";
import { type GradientIconProps, Icons } from "../Icons";
import { MainButton } from "../shared/MainButton";

export const SocialIcon = ({ name, link }: { name: string; link: string }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const iconsMap: Record<string, React.FC<GradientIconProps>> = {
    telegram: Icons.gradientTelegramIcon,
    x: Icons.gradientXIcon,
    chat: Icons.gradientChatIcon,
    qr: Icons.gradientQrIcon,
  };

  const IconComponent = iconsMap[name] || Icons.gradientXIcon;

  return (
    <a
      className="cursor-pointer flex items-end justify-center"
      href={link}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered ? (
        <IconComponent className="h-5 select-none" gradientColors={["#fffacd", "#aaffb3"]} />
      ) : (
        <IconComponent className="h-5 select-none" gradientColors={["#00e218", "#00e218"]} />
      )}
    </a>
  );
};

type QrCodePopoverProps = {
  children: React.ReactNode;
  position?: "top" | "bottom";
};

export const QrCodePopover: React.FC<QrCodePopoverProps> = ({ children, position = "bottom" }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={popoverRef}>
      <div onClick={togglePopover} onKeyDown={() => {}}>
        {children}
      </div>

      {isPopoverOpen && (
        <div
          className={`absolute py-[30px] px-[40px] z-10 flex items-center justify-center bg-[#0B0B0B] rounded shadow-lg border-[0.5px] border-[#313131] ${
            position === "top" ? "bottom-full mb-2" : "top-full mt-2"
          }`}
        >
          <div className="flex flex-col justify-center items-center w-[200px] h-[251px]">
            <p className="text-[24px] text-white">Download Mobile App</p>

            <img
              draggable={false}
              src="/download-mobile-app-qrcode.svg"
              alt="QR Code"
              style={{ width: "200px", height: "200px" }}
              className="mt-[16px] w-[200px] h-[200px] min-w-[200px] min-h-[200px] object-contain flex-none"
            />
          </div>
        </div>
      )}
    </div>
  );
};

type Props = {
  onClick: () => void;
};

export const Header = ({ onClick }: Props) => (
  <>
    <header className="fixed top-0 left-0 right-0 bg-black z-50 md:block hidden">
      <div className="flex flex-row items-center justify-between max-w-[1200px] mx-auto h-20">
        <div className="flex items-center justify-between gap-16">
          <button type="button" className="cursor-pointer" onClick={onClick}>
            <img src="/logo.svg" alt="Thumba" className="h-5" id="logo" />
          </button>

          <div className="flex items-center justify-between gap-10">
            <a href="/" className="text-[#989898] hover:link-gradient cursor-`pointer">
              HOME
            </a>
            <a href="/faq" className="text-[#989898] hover:link-gradient cursor-pointer">
              FAQ
            </a>
          </div>
        </div>

        <div className="flex items-center justify-between gap-6">
          <QrCodePopover>
            <SocialIcon name="qr" link="#" />
          </QrCodePopover>

          <SocialIcon name="telegram" link="https://t.me/ThumbaAnnouncement" />
          <SocialIcon name="x" link="https://x.com/Thumba_official" />
          <SocialIcon name="chat" link="https://t.me/ThumbaSupportBot" />

          <a href="https://t.me/ThumbaGameBot">
            <MainButton height="35px" className="px-[50px] text-[16px]">
              Play Now
            </MainButton>
          </a>
        </div>
      </div>
    </header>
  </>
);
