import { env } from "@/config/env";
import type { CampaignStep } from "@/hooks/useUserCampaign";
import { useUserInvitedCount } from "@/hooks/useUserInvitedCount";
import { SubButton, SubLinkButton } from "../shared/SubButton";
import { CampaignButton } from "./CampaignButton";

interface Props {
  campaign: CampaignStep;
  telegramUserId: number;
}

export const SubCampaign: React.FC<Props> = ({ campaign, telegramUserId }) => {
  const { invitedCount } = useUserInvitedCount(telegramUserId);
  const inviteLink = `${env.TELEGRAM_URL}?start=in-${campaign.invitationCode}`;

  const text = "Join the #Thumba #2000TON Airdrop.\nMessi or Ronaldo -who would YOU choose?";

  return (
    <div className="w-full text-left">
      <div className="p-[22px]">
        <p className="text-white text-[20px] leading-[35px] pt-4">Sub Campaign</p>

        <div className="flex flex-col  justify-between items-center p-4 rounded-[20px] border border-white/20 mt-2">
          <div className="flex flex-row gap-1">
            <img src="/thumba-icon.svg" alt="thumba" className="w-6 h-6" />
            <img src="/thumba-icon.svg" alt="thumba" className="w-6 h-6" />
            <img src="/thumba-icon.svg" alt="thumba" className="w-6 h-6" />
          </div>

          <p className="text-white text-[19px] leading-[22px] pt-4 text-center">
            <span className="text-[36px] leading-[22px]">{invitedCount}</span>
            <br />
            friends
          </p>
        </div>

        <CampaignButton
          currentStep={campaign.currentStep}
          step={1}
          telegramUserId={telegramUserId}
          title="Join the 2nd Campaign"
          activeChildren={
            <>
              <img src="/telegram-black.svg" alt="Play" className="h-5" />
              THUMBA bot​
            </>
          }
          inactiveChildren={
            <>
              <img src="/telegram-white.svg" alt="Play" className="h-5" />
              THUMBA bot​
            </>
          }
        />

        <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
          <span className="text-[#545454]">2.</span> Invite friends to the Campaign
        </p>
        <p className="text-[#989898]/50 text-[12px] leading-[16px] text-left mb-4">
          Share link to this campaign with your friends and followers on telegram, X, etc...
        </p>
        <div className="flex flex-col gap-2">
          <SubButton
            className="w-full"
            height="35px"
            onClick={() => {
              navigator.clipboard.writeText(`${env.TELEGRAM_URL}?start=in-${campaign.invitationCode}`);
              alert("COPIED!");
            }}
          >
            <img src="/copy.svg" alt="Play" className="h-3" />
            <span className="text-[10px]">{inviteLink}</span>
          </SubButton>
          <SubLinkButton
            className="w-full"
            height="35px"
            href={`https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(text)}`}
          >
            <img src="/telegram-white.svg" alt="Play" className="h-3" />
            Share
          </SubLinkButton>
        </div>

        <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
          <span className="text-[#545454]">3.</span> Friends Complete main Campaign
        </p>
        <p className="text-[#989898] text-[14px] leading-[18px] text-left mb-4">
          ※The numbers of the friends you've invited to the CAMPAIGN will be counted
        </p>
      </div>
    </div>
  );
};
