import { cn } from "@/utils";
import { useState } from "react";
import { Icons } from "../Icons";
import { QrCodePopover, SocialIcon } from "./Header";

export const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div className="md:flex-col md:items-center pt-[20px] pb-[60px] w-full lg:w-5/6 xl:w-3/4 2xl:w-2/3 mx-auto hidden md:flex">
      <div className="w-full flex flex-raw justify-between items-center">
        <div className="flex items-center flex-raw justify-around mb-[20px] gap-4">
          <p className="text-gradient text-[24px] leading-[24px]">
            {" "}
            <span className="text-[#989898] mr-[10px]">©</span>DraftBeasts Inc.
          </p>

          <a className={"text-[#989898] text-[18px] ml-[40px] hover:link-gradient"} href="/privacy-policy">
            privacy policy
          </a>

          <a className="text-[#989898] text-[18px] ml-[40px] hover:link-gradient" href="/aml-policy">
            AML policy
          </a>
          <a className="text-[#989898] text-[18px] ml-[40px] hover:link-gradient" href="/terms-and-conditions">
            Terms & conditions
          </a>
        </div>

        <div className="flex items-center justify-center gap-4">
          <QrCodePopover position="top">
            <button
              className="text-[#00e218] hover:text-black flex items-center space-x-[4px] rounded-[50px] border border-[#313131] py-2 px-[15px] bg-gradient-to-r hover:from-[#FFFACD] hover:to-[#AAFFB3]"
              type="button"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Icons.qrCode className="w-[14px] h-[14px]" />

              <span className={cn("text-[#989898] text-[16px] leading-[35px]", isHovered && "text-black")}>
                Download Mobile App
              </span>
            </button>
          </QrCodePopover>

          <SocialIcon name="telegram" link="https://t.me/+7siGL7Uhq9gyNmU1" />
          <SocialIcon name="x" link="https://x.com/Thumba_official" />
          <SocialIcon name="chat" link="https://t.me/ThumbaSupportBot" />
        </div>
      </div>
    </div>
  );
};
