import { useEffect, useState } from "react";
import { Icons } from "../Icons";
import { SocialIcon } from "../pc/Header";

type Props = {
  onClick: () => void;
};

export const Header: React.FC<Props> = ({ onClick }: Props) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    // Disable scrolling when expanded is true
    if (expanded) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up the overflow-hidden class on component unmount
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [expanded]);

  return (
    <header className="md:hidden">
      {!expanded && (
        <div className="flex items-center justify-between h-18 pt-10 pb-[15px] px-5 fixed top-0 left-0 right-0 bg-black z-50">
          <div className="flex items-center justify-between gap-3">
            <Icons.menu className="cursor-pointer text-[#22E215]" onClick={() => setExpanded(true)} />

            <button type="button" className="cursor-pointer" onClick={onClick}>
              <img src="/logo.svg" alt="Thumba" className="h-5" id="logo" />
            </button>

            <a href="/" className="uppercase hover">
              Home
            </a>

            <a href="/faq" className="uppercase hover">
              FAQ
            </a>
          </div>

          <div className="flex items-center justify-between gap-3">
            <SocialIcon name="telegram" link="https://t.me/+7siGL7Uhq9gyNmU1" />
            <SocialIcon name="x" link="https://x.com/Thumba_official" />
            <SocialIcon name="chat" link="https://t.me/ThumbaSupportBot" />
          </div>
        </div>
      )}

      {expanded && (
        <>
          {/* Overlay */}
          <div className="fixed inset-0 bg-black bg-opacity-70 z-40" />

          {/* Expanded content */}
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-black z-50 flex items-center justify-center h-18 pt-10 pb-[15px] px-5">
            <div className="w-full h-full relative">
              <div>
                <button type="button" className="cursor-pointer" onClick={onClick}>
                  <img src="/logo.svg" alt="Thumba" className="h-5" id="logo" />
                </button>

                <Icons.close
                  className="absolute top-0 right-0 cursor-pointer text-[#989898]"
                  onClick={() => setExpanded(false)}
                />
              </div>

              <div className="flex flex-col gap-4 mt-7">
                <a className="text-white hover:link-gradient text-[28px] leading-[28px]" href="/">
                  Home
                </a>

                <a className="text-white hover:link-gradient text-[28px] leading-[28px]" href="/faq">
                  FAQ
                </a>

                <a href="/privacy-policy" className="text-white hover:link-gradient text-[28px] leading-[28px]">
                  Privacy Policy
                </a>

                <a href="/aml-policy" className="text-white hover:link-gradient text-[28px] leading-[28px]">
                  AML Policy
                </a>

                <a href="/terms-and-conditions" className="text-white hover:link-gradient text-[28px] leading-[28px]">
                  Terms & Conditions
                </a>
              </div>

              <div className="absolute bottom-[80px] flex justify-center w-full">
                <div className="flex items-center justify-between gap-3">
                  <SocialIcon name="telegram" link="https://t.me/+7siGL7Uhq9gyNmU1" />
                  <SocialIcon name="x" link="https://x.com/Thumba_official" />
                  <SocialIcon name="chat" link="https://t.me/ThumbaSupportBot" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
};
