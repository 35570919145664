import { cn } from "@/utils";

type IconProps = React.HTMLAttributes<SVGElement>;

export type GradientIconProps = IconProps & { gradientColors?: string[] };

export const Icons = {
  menu: (props: IconProps) => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Menu</title>
      <rect x={1} y={4.5} width={22} height={1} fill="currentColor" />
      <rect x={1} y={11.5} width={22} height={1} fill="currentColor" />
      <rect x={1} y={18.5} width={22} height={1} fill="currentColor" />
    </svg>
  ),
  close: (props: IconProps) => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Close</title>
      <g id="ic/close/24/gray">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.3198 3.34925L3.6127 4.05635L11.1127 11.5564L4.05635 18.6127L4.76345 19.3198L11.8198 12.2635L19.169 19.6127L19.8762 18.9056L12.5269 11.5564L20.3198 3.76346L19.6127 3.05635L11.8198 10.8492L4.3198 3.34925Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  qrCode: (props: IconProps) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      {...props}
    >
      <title>QR Code</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33105 2.24984C1.33105 1.92767 1.59222 1.6665 1.91439 1.6665H5.99772C6.3199 1.6665 6.58105 1.92767 6.58105 2.24984V6.33317C6.58105 6.65535 6.3199 6.9165 5.99772 6.9165H1.91439C1.59222 6.9165 1.33105 6.65535 1.33105 6.33317V2.24984ZM3.08105 3.99984C3.08105 3.67767 3.34222 3.4165 3.66439 3.4165H4.24772C4.56988 3.4165 4.83105 3.67767 4.83105 3.99984V4.58317C4.83105 4.90533 4.56988 5.1665 4.24772 5.1665H3.66439C3.34222 5.1665 3.08105 4.90533 3.08105 4.58317V3.99984Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33105 8.66634C1.33105 8.34417 1.59222 8.08301 1.91439 8.08301H5.99772C6.3199 8.08301 6.58105 8.34417 6.58105 8.66634V12.7497C6.58105 13.0718 6.3199 13.333 5.99772 13.333H1.91439C1.59222 13.333 1.33105 13.0718 1.33105 12.7497V8.66634ZM3.66439 9.83301C3.34222 9.83301 3.08105 10.0942 3.08105 10.4163V10.9997C3.08105 11.3218 3.34222 11.583 3.66439 11.583H4.24772C4.56988 11.583 4.83105 11.3218 4.83105 10.9997V10.4163C4.83105 10.0942 4.56988 9.83301 4.24772 9.83301H3.66439Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33138 1.6665C8.00921 1.6665 7.74805 1.92767 7.74805 2.24984V6.33317C7.74805 6.65535 8.00921 6.9165 8.33138 6.9165H12.4147C12.7369 6.9165 12.998 6.65535 12.998 6.33317V2.24984C12.998 1.92767 12.7369 1.6665 12.4147 1.6665H8.33138ZM9.49805 3.99984C9.49805 3.67767 9.75921 3.4165 10.0814 3.4165H10.6647C10.9869 3.4165 11.248 3.67767 11.248 3.99984V4.58317C11.248 4.90533 10.9869 5.1665 10.6647 5.1665H10.0814C9.75921 5.1665 9.49805 4.90533 9.49805 4.58317V3.99984Z"
        fill="currentColor"
      />
      <path
        d="M8.33138 8.08301C8.00921 8.08301 7.74805 8.34417 7.74805 8.66634V9.24967C7.74805 9.57185 8.00921 9.83301 8.33138 9.83301H8.91471V10.9997C8.91471 11.3218 9.17587 11.583 9.49805 11.583H10.0814C10.4036 11.583 10.6647 11.3218 10.6647 10.9997V9.83301C10.6647 9.51083 10.4036 9.24967 10.0814 9.24967H9.49805V8.66634C9.49805 8.34417 9.23689 8.08301 8.91471 8.08301H8.33138Z"
        fill="currentColor"
      />
      <path
        d="M8.33138 11.583C8.00921 11.583 7.74805 11.8442 7.74805 12.1663V12.7497C7.74805 13.0718 8.00921 13.333 8.33138 13.333H8.91471C9.23689 13.333 9.49805 13.0718 9.49805 12.7497V12.1663C9.49805 11.8442 9.23689 11.583 8.91471 11.583H8.33138Z"
        fill="currentColor"
      />
      <path
        d="M10.6641 8.66634C10.6641 8.34417 10.9252 8.08301 11.2474 8.08301H11.8307C12.1529 8.08301 12.4141 8.34417 12.4141 8.66634V9.24967C12.4141 9.57185 12.1529 9.83301 11.8307 9.83301H11.2474C10.9252 9.83301 10.6641 9.57185 10.6641 9.24967V8.66634Z"
        fill="currentColor"
      />
      <path
        d="M11.2474 11.583C10.9252 11.583 10.6641 11.8442 10.6641 12.1663V12.7497C10.6641 13.0718 10.9252 13.333 11.2474 13.333H11.8307C12.1529 13.333 12.4141 13.0718 12.4141 12.7497V12.1663C12.4141 11.8442 12.1529 11.583 11.8307 11.583H11.2474Z"
        fill="currentColor"
      />
    </svg>
  ),
  gradientChevronIcon: ({
    isSelected = false,
    gradientColors = ["#00e218", "#ffe500"],
    className,
  }: IconProps & { isSelected: boolean; gradientColors?: string[] }) => {
    return (
      <svg
        className={cn("w-5 h-5 transform transition-transform", isSelected && "rotate-180", className)}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="url(#chevronIconGradient)"
      >
        <defs>
          <linearGradient id="chevronIconGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: gradientColors[0], stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: gradientColors[1], stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <title>Chevron Down</title>
        <path d="M19 9l-7 7-7-7" />
      </svg>
    );
  },
  gradientTelegramIcon: ({ gradientColors = ["#00e218", "#00e218"], className }: GradientIconProps) => {
    const id = `TelegramIconGradient-${gradientColors[0].substring(1)}-${gradientColors[1].substring(1)}`;

    return (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn("w-5 h-5", className)}>
        <title>Gradient X Icon</title>
        <defs>
          <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset="100%" stopColor={gradientColors[1]} />
          </linearGradient>
        </defs>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.49314 10.7489C8.32354 8.20867 12.2114 6.534 14.1567 5.72489C19.7109 3.4147 20.865 3.01339 21.6173 3.00014C21.7827 2.99723 22.1526 3.03823 22.3923 3.23267C22.5946 3.39685 22.6503 3.61863 22.6769 3.77429C22.7035 3.92995 22.7367 4.28455 22.7103 4.56163C22.4094 7.7241 21.107 15.3986 20.4444 18.9406C20.1641 20.4394 19.6121 20.9419 19.0776 20.9911C17.9162 21.098 17.0342 20.2235 15.9093 19.4861C14.1491 18.3323 13.1546 17.614 11.446 16.488C9.47138 15.1868 10.7514 14.4716 11.8768 13.3027C12.1713 12.9969 17.2886 8.34226 17.3877 7.92001C17.4 7.86721 17.4115 7.67036 17.2946 7.56642C17.1777 7.46248 17.0051 7.49802 16.8805 7.52629C16.704 7.56636 13.8919 9.42501 8.44438 13.1022C7.6462 13.6503 6.92322 13.9174 6.27546 13.9034C5.56136 13.888 4.18771 13.4996 3.16654 13.1677C1.91404 12.7606 0.918579 12.5453 1.00526 11.8539C1.05041 11.4937 1.54637 11.1254 2.49314 10.7489Z"
          fill={`url(#${id})`}
        />
      </svg>
    );
  },
  gradientXIcon: ({ gradientColors = ["#00e218", "#00e218"], className }: GradientIconProps) => {
    const id = `XGradient-${gradientColors[0].substring(1)}-${gradientColors[1].substring(1)}`;

    return (
      <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn("w-5 h-5", className)}>
        <title>Gradient X Icon</title>
        <defs>
          <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset="100%" stopColor={gradientColors[1]} />
          </linearGradient>
        </defs>
        <path
          d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"
          fill={`url(#${id})`}
        />
      </svg>
    );
  },
  gradientChatIcon: ({ gradientColors = ["#00e218", "#00e218"], className }: GradientIconProps) => {
    const id = `ChatGradient-${gradientColors[0].substring(1)}-${gradientColors[1].substring(1)}`;

    return (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn("w-5 h-5", className)}>
        <title>Gradient X Icon</title>
        <defs>
          <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset="100%" stopColor={gradientColors[1]} />
          </linearGradient>
        </defs>
        <path
          d="M16.7825 2H7.26083C4.59477 2 2.5 4.09477 2.5 6.76083V20.0912C2.5 20.8529 2.88087 21.5194 3.54738 21.8051C4.2139 22.0907 4.97563 22.0907 5.54693 21.6146L6.78475 20.6625C7.26083 20.2816 7.92735 20.0912 8.49865 20.0912H16.7825C19.4486 20.0912 21.5433 17.9964 21.5433 15.3303V6.76083C21.5433 4.09477 19.4486 2 16.7825 2ZM11.8312 16.2825C11.2599 16.2825 10.8791 15.9016 10.8791 15.3303C10.8791 14.759 11.2599 14.3782 11.8312 14.3782C12.4025 14.3782 12.7834 14.759 12.7834 15.3303C12.7834 15.9016 12.3073 16.2825 11.8312 16.2825ZM14.3069 11.9025L13.6403 12.4738C13.4499 12.6643 13.2595 12.8547 13.069 13.0451C12.8786 13.3308 12.593 13.426 12.3073 13.426C12.1169 13.426 11.9264 13.3308 11.736 13.2356C11.2599 12.9499 11.1647 12.3786 11.4504 11.9025C11.736 11.6169 12.0217 11.236 12.3073 11.0456C12.4977 10.8551 12.6882 10.6647 12.9738 10.4743C13.7356 9.80776 14.0212 9.33168 13.926 8.76038C13.8308 8.18908 13.3547 7.713 12.7834 7.61778C12.4025 7.52256 11.9264 7.61778 11.6408 7.90343C11.2599 8.2843 11.0695 8.66516 11.0695 9.14125C11.0695 9.71255 10.6886 10.0934 10.1173 10.0934C9.54603 10.0934 9.16516 9.71255 9.16516 9.14125C8.97473 7.33213 10.403 5.71345 12.2121 5.52301C14.0212 5.33258 15.6399 6.76083 15.8303 8.56995C15.9255 9.90298 15.3542 11.1408 14.3069 11.9025Z"
          fill={`url(#${id})`}
        />
      </svg>
    );
  },
  gradientQrIcon: ({ gradientColors = ["#00e218", "#00e218"], className }: GradientIconProps) => {
    const id = `QrGradient-${gradientColors[0].substring(1)}-${gradientColors[1].substring(1)}`;

    return (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn("w-5 h-5", className)}>
        <title>QR Code</title>
        <defs>
          <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset="100%" stopColor={gradientColors[1]} />
          </linearGradient>
        </defs>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3C2 2.44772 2.44772 2 3 2H10C10.5523 2 11 2.44772 11 3V10C11 10.5523 10.5523 11 10 11H3C2.44772 11 2 10.5523 2 10V3ZM5 6C5 5.44772 5.44772 5 6 5H7C7.55228 5 8 5.44772 8 6V7C8 7.55228 7.55228 8 7 8H6C5.44772 8 5 7.55228 5 7V6Z"
          fill={`url(#${id})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 14C2 13.4477 2.44772 13 3 13H10C10.5523 13 11 13.4477 11 14V21C11 21.5523 10.5523 22 10 22H3C2.44772 22 2 21.5523 2 21V14ZM6 16C5.44772 16 5 16.4477 5 17V18C5 18.5523 5.44772 19 6 19H7C7.55228 19 8 18.5523 8 18V17C8 16.4477 7.55228 16 7 16H6Z"
          fill={`url(#${id})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 2C13.4477 2 13 2.44772 13 3V10C13 10.5523 13.4477 11 14 11H21C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14ZM16 6C16 5.44772 16.4477 5 17 5H18C18.5523 5 19 5.44772 19 6V7C19 7.55228 18.5523 8 18 8H17C16.4477 8 16 7.55228 16 7V6Z"
          fill={`url(#${id})`}
        />
        <path
          d="M14 13C13.4477 13 13 13.4477 13 14V15C13 15.5523 13.4477 16 14 16H15V18C15 18.5523 15.4477 19 16 19H17C17.5523 19 18 18.5523 18 18V16C18 15.4477 17.5523 15 17 15H16V14C16 13.4477 15.5523 13 15 13H14Z"
          fill={`url(#${id})`}
        />
        <path
          d="M14 19C13.4477 19 13 19.4477 13 20V21C13 21.5523 13.4477 22 14 22H15C15.5523 22 16 21.5523 16 21V20C16 19.4477 15.5523 19 15 19H14Z"
          fill={`url(#${id})`}
        />
        <path
          d="M18 14C18 13.4477 18.4477 13 19 13H20C20.5523 13 21 13.4477 21 14V15C21 15.5523 20.5523 16 20 16H19C18.4477 16 18 15.5523 18 15V14Z"
          fill={`url(#${id})`}
        />
        <path
          d="M19 19C18.4477 19 18 19.4477 18 20V21C18 21.5523 18.4477 22 19 22H20C20.5523 22 21 21.5523 21 21V20C21 19.4477 20.5523 19 20 19H19Z"
          fill={`url(#${id})`}
        />
      </svg>
    );
  },
};
